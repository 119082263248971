import React from 'react'

class DynamicContainer extends React.Component {

   

    render () {
        return (
            <>
            <div className='dynamicContainer'>
                {this.props.children}
            </div>
            </>
        )
    }
}

export default DynamicContainer;












