import React from 'react'

class Loading extends React.Component {

    render () {
        return(
            <div className='loading'>
                <p></p>
                <p></p>
                <p></p>
            </div>
        )
    }
}

export default Loading