import React from 'react'
import speaker from '../../images/volume.svg'
import Loading from './loading.js'
import cleanWord from '../../costumJavascript/cleanWord.js'
import contextObject from '../../context.js'

class WordsItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          wordPlays: false,
          plays: true
        }
      }
    render () {

        var cleaned = cleanWord(this.props.german)

    
        return (
            <>
            <div className='wordsItem'>
                <div>
                    <p>{this.props.german}</p>
                    <p>{this.props.translated}</p>
                </div>
                <div className='wordWikiInfo'>  
                    <a href={`https://de.wiktionary.org/wiki/${cleaned}`} rel="noreferrer" target='_blank'>Wiki</a>
                </div>
                <div className='toSpeak' onClick={

                    () => {
                        
                        if ('speechSynthesis' in window) {
                            
                            
                            var utterance = new SpeechSynthesisUtterance(this.props.german);
                            utterance.lang = 'de-DE';
                            utterance.pitch = 1.2;
                            utterance.rate = 0.90;
                            utterance.volume = 0.9;

                            //console.log(utterance.text, 1);

                            speechSynthesis.cancel()
                            speechSynthesis.speak(utterance)
                            
                            
                            //////////////
                            /*
                            utterance.onerror = function(event) {
                                console.log('An error has occurred with the speech synthesis: ' + event.error);
                              }

                              
                            */
                              utterance.addEventListener('start',  (event) => {
                                console.log('start', 200);
                                this.setState({
                                    plays: false
                                })  
                            })
                            utterance.addEventListener('boundary',  (event) => {
                                console.log('boundary', 200);

                                this.setState({
                                    plays: true
                                })  
                            })
                            utterance.addEventListener('end',  (event) => {

                                if (this.state.plays === false) {
                                    console.log('end', 800);

                                    this.setState({
                                        plays: true
                                    })   
                                }
                                
                            })
                            utterance.onerror = function(event) {
                                console.log('An error has occurred with the speech synthesis: ' + event.error);
                              }
                                
                            
                        } else {
                            console.log('Text-to-speech not supported in this browser.');
                            this.context.issueTrue()
                        }
                    }//arrow function ends
                }>
                {this.state.plays == true ? 
                 <svg width="20" height="20" viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <g clip-path="url(#clip0)">
                 <path d="M278.944 17.577C273.376 14.921 266.816 15.625 262.016 19.497L106.368 144.009H32C14.368 144.009 0 158.377 0 176.009V304.009C0 321.641 14.368 336.009 32 336.009H106.368L261.984 460.521C264.896 462.825 268.448 464.009 272 464.009C274.368 464.009 276.736 463.497 278.944 462.441C284.48 459.753 288 454.153 288 448.009V32.009C288 25.865 284.48 20.265 278.944 17.577ZM96 304.009H32V176.009H96V304.009ZM256 414.697L128 312.297V167.721L256 65.321V414.697Z" fill="rgb(73, 73, 73)"/>
                 <path d="M369.024 126.857C362.72 120.617 352.608 120.713 346.4 126.985C340.192 133.289 340.256 143.401 346.528 149.609C370.688 173.513 384 205.609 384 240.009C384 274.409 370.688 306.505 346.528 330.409C340.224 336.617 340.16 346.729 346.4 353.033C349.536 356.201 353.664 357.769 357.76 357.769C361.824 357.769 365.888 356.233 369.024 353.129C399.328 323.209 416 283.049 416 240.009C416 196.969 399.328 156.809 369.024 126.857Z" fill="rgb(73, 73, 73)"/>
                 <path d="M414.144 81.769C407.872 75.561 397.728 75.593 391.52 81.865C385.312 88.137 385.344 98.281 391.616 104.489C427.968 140.553 448 188.681 448 240.009C448 291.337 427.968 339.465 391.616 375.529C385.344 381.737 385.312 391.881 391.52 398.153C394.656 401.321 398.752 402.889 402.88 402.889C406.944 402.889 411.008 401.353 414.144 398.249C456.608 356.137 480 299.945 480 240.009C480 180.073 456.608 123.881 414.144 81.769Z" fill="rgb(73, 73, 73)"/>
                 </g>
                 <defs>
                 <clipPath id="clip0">
                 <rect width="480" height="480" fill="white"/>
                 </clipPath>
                 </defs>
                 </svg>
                : <Loading/>}

                </div>

            </div>
            </>
        )
    }
}
WordsItem.contextType = contextObject

export default WordsItem