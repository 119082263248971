import React from 'react'
import WordsItem from './components/wordsItem.js'
import PagButtons from './components/pagButtons.js'
import Spinner from './components/spinnerBasic.js'
import { withRouter } from "react-router";
import contextObject from '../context.js'
import { categories } from '../categoriesInformations.js'

class Words extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
          items: [],
          start: 0,
          end: 20,
          howManyWords: '',
          pagginationStarts: 0,
          whichButton: 1
        }
    }

    getClickedButton(key) {
        this.setState({
            whichButton: key,
            pagginationStarts: key - 1
          })
  
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
      }//my way

    

    

    render() {
        
       

        //var paginationButtons = Math.ceil(this.props.words.length / 20)

        var pagElements = []
        
        var displayWords = []
        if (this.props.words.length != 0) {                    
            
        
        for (let i = 0; i < this.props.words.length; i += 20) {
            var nu = this.props.words.slice(i, i + 20)
            displayWords.push(nu)
        }

        for (var i = 0; i < displayWords.length; i++) {   
            
            pagElements.push(
                <PagButtons 
                number={i+1} 
                //onclick get the state of each button
                set={this.getClickedButton.bind(this)}
                activeThis={this.state.whichButton == i + 1 ? true : false}
                />)
        }
    }
        return (
            <>
            <div className='wordsBox'>

                    {  
                        this.props.words.length != 0 ?
                        displayWords[this.state.pagginationStarts].map(
                        (each) => { return <WordsItem german={each.german} translated={this.context.lang == 'greek' ? each.greek : each.english }/>
                        }) : ''
                        
                    }

            </div>
            <div className='paginationContainer'>
                {pagElements}
            </div>
            </>
        )
    }
}

Words.contextType = contextObject
export default withRouter(Words)
