import React from 'react'
import MainWords from '../categories/mainWords.js'
import Info from '../info/info.js'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

class Screen extends React.Component {

    
 
    render() {

        return (
            <>
            
            <div className='screen'>
                {
                /*
                {this.props.display == 'categories' ?  200 : '' }
                {this.props.display == 'info' ? <Info /> : ''}
                */
                }
                {this.props.children}
                <div className='rights'>
                    <p style={{color: '#919191'}}>all rights reserved Ioannis Mentesidis &copy; 2021</p>
                </div>
            </div>
            </> 
        )
    }
}

export default Screen