import React from 'react'
import NavBar from './NavBar.js'
import SideBar from './SideBar.js'
import Screen from './Screen.js'
import MainWords from '../categories/mainWords.js'
import Info from '../info/info.js'
import { withRouter } from "react-router";
import { Route, Switch } from 'react-router-dom';
import NotFound from './notFound.js'

class App extends React.Component {
    constructor (props) {
        super(props)
        this.state = { 
            sideBarOneClosed: true,
            sideBarTwoClosed: false,
            //problem with sidebar links
            
        }
    }

    burger() {
        this.setState({sideBarOneClosed: this.state.sideBarOneClosed == true ? false : true})
    }

    render() {
        
        return (
                <>
                <NavBar burgerClicked={this.burger.bind(this)}/>
                <section>
                    <SideBar 
                    toTop={this.props.toTop} 
                    toClose={this.state.sideBarOneClosed}
                    display={this.props.display}
                    name={'sideBar'}
                    />
                    <SideBar 
                    toTop={this.props.toTop} 
                    toClose={this.state.sideBarTwoClosed}
                    display={this.props.display}
                    name={'sideBarDashboard'}
                    />
                    <Screen>
                    <Switch>
                        <Route path="/app/categories" >
                            <MainWords toTop={this.props.toTop}/> 
                        </Route>
                        <Route path='/app/info'>
                            <Info />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                    </Screen>
                </section>
                        </>
        )    
    }
}
  


export default withRouter(App)