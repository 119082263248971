import React from 'react'
import { Link } from 'react-router-dom';
import AboutIcon from './aboutIcon';
import CategorieIcon from './categoriesIcon';
import { withRouter } from "react-router";
import contextObject from '../context.js'

class SideBar extends React.Component {
    constructor (props) {
        super(props)
        this.clicked = this.clicked.bind(this)
        this.state = {
            clicked: this.props.display,
            categoryActive: this.props.location.pathname != '/app/info' ? true : false,
            aboutActive: this.props.location.pathname == '/app/info' ? true : false
        }
    }

    clicked(category, about) {
        this.setState({categoryActive: category, aboutActive: about})
    }

    render () {

        var active = 'white'
        var inActive =  'rgba(255, 255, 255, 0.588)'

        return (
            <>
            <div className={this.props.name}
            style={
                
                this.props.toClose == true ? {transform: 'translateX(-120px)'} : {transform: 'translateX(0)'}
                
                }>

                <ul>

                    <li>
                        <Link to='/app/categories'>
                            <CategorieIcon color={this.state.categoryActive == true ? active : inActive} clicked={this.clicked} lang={this.context.lang}/>
                        </Link>
                    </li>

                    <li>
                        <Link to='/app/info'>
                            <AboutIcon color={this.state.aboutActive == true ? active : inActive} clicked={this.clicked} lang={this.context.lang}/>
                        </Link>
                    </li>

                </ul>

                <Link to='/' exact='true'>
                    <button onClick={this.props.toTop}>EXIT</button>
                </Link>


            </div>
            </>
        )
    }
}
SideBar.contextType = contextObject
export default withRouter(SideBar)