import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { categories } from '../categoriesInformations.js'
import { level } from '../costumBack.js';
import contextObject from '../context.js'
import  { langName } from './nameOfCategory.js'


class ToolBar extends React.Component {

    render() {

        
        var numberOfCategory = categories.findIndex((each) => {
            if (each[langName(this.context.lang)] == this.props.match.params.categoryName) {
                return each
            }
            })
        var category = this.props.match.params.categoryName

        var numberOfPart = parseInt(this.props.match.params.whichPart)
        var path = this.props.location.pathname
        var direction = level(path, category, numberOfPart)


        function title(categoryName, partnumber, state) {

            if (categoryName == undefined) {

                return <p>{state.lang === 'greek' ? 'Κατηγορίες' : 'Categories'}</p>

            } else if (categoryName != undefined && isNaN(partnumber)) {    

                return <p>{`${categoryName}`}</p>

            } else if (partnumber >= 0) {

                return   (state.lang === 'greek' ? 
                <p>{`${categoryName} μέρος ${partnumber + 1}ο`}</p> : 
                <p>{`${categoryName} part ${partnumber + 1}`}</p>
                )
            }
        }
        
        return (
            <div className='toolBar'>

                    <Link to={direction}>
                        <div>
                        {this.props.location.pathname == '/app/categories' ? '' :              
                            <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3384 1.46614L0.849816 14.2502C0.398645 14.6483 0.398645 15.3517 0.849816 15.7498L15.3384 28.5339C15.9841 29.1036 17 28.6451 17 27.784V2.21597C17 1.35486 15.9841 0.896403 15.3384 1.46614Z" />
                            <path d="M36 9H18C17.4477 9 17 9.44772 17 10V20C17 20.5523 17.4477 21 18 21H36C36.5523 21 37 20.5523 37 20V10C37 9.44772 36.5523 9 36 9Z" />
                            </svg>}        
                        </div>
                    </Link>

                        {title(category, numberOfPart, this.context)}

                    <div className='dynamicButtons'>
                        { 
                        this.props.exerciseButton == true ?
                        <button>

                            <Link to={`/app/categories/${categories[numberOfCategory][langName(this.context.lang)]}/${numberOfPart}/exercises`}>
                                {this.context.lang === 'greek' ? 'ΑΣΚΗΣΕΙΣ' : 'EXERCISES'}  
                            </Link>

                        </button> 
                        : ''    
                        }
                    </div>

                </div>
        )
    }
}

ToolBar.contextType = contextObject

export default withRouter(ToolBar)