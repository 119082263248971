import React from 'react'

class Spinner extends React.Component {

    render () {
        return(
            <div 
            className='spinnerContainer' 
            style={this.props.active == true ? {display: 'flex'} : {display: 'none'}
            }>
                <div>
                </div>
            </div>
        )
    }
}

export default Spinner






