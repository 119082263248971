import React from 'react'
import contextObject from '../context.js'
import { categories } from '../categoriesInformations.js'
import { Route, withRouter, Link } from 'react-router-dom';  
import { ReactComponent as Right } from '../images/check2.svg'
import { ReactComponent as Wrong } from '../images/remove.svg'

import wrong from '../images/remove.svg'

var wordOptions = ''


class MultipleChoice extends React.Component {
    constructor(props, context) {
        super(props)
        this.randomArrayWithCorrectAnswer = this.randomArrayWithCorrectAnswer.bind(this)

        this.state = {
            numberOfWord: 0,
            array: '',
            generate: true,
            selectedAnswer: null,
            wrongAnswer: null,
            initStyling: true,
            userHasSelected: false,
            image: ''
        }
    }

   styleCorrectOrFalse (init, isRight, isFalse) {
       
        if (init == true) {
           return {borderColor: 'rgb(37, 37, 37)'} 
        }

        if (isRight == true) {
            return {borderColor: 'rgba(12, 255, 8, 1)', backgroundColor: 'rgba(12, 255, 8, 0.08)'} 
        } 

        else if (isFalse == true) {
            return {borderColor: 'rgba(243, 0, 0, 1)', backgroundColor: 'rgba(243, 0, 0, 0.08)'} 
        }
   }

   randomArrayWithCorrectAnswer(generate) {
        if (generate == true) {
            
        
        var rightAnswer = ''
        var wordsLength = ''
        
        rightAnswer = this.context.lang === 'greek' ?
        this.props.words[this.state.numberOfWord].greek :
        this.props.words[this.state.numberOfWord].english

        wordsLength = this.props.words.length

        const generateRandomNumber = () => {
            var randomNumber = Math.floor(Math.random() * wordsLength)
             if (randomNumber == this.state.numberOfWord) {
                 //console.log('trapped: ', randomNumber);
                 return generateRandomNumber()
             }
             return randomNumber
        }

        const randomArrayWithNumbers = () => {
            var arrayOfRandomNumbers = []
            for (let i = 0; i < 3; i++) {
                var uniqueRandom = generateRandomNumber()
                arrayOfRandomNumbers.push(uniqueRandom)  
            }
            var toCompare = [...new Set(arrayOfRandomNumbers)]

                //console.log(toCompare, 'new set');
            if (toCompare.length < 3) {
                //console.log(toCompare, 'without duplicates');
                return randomArrayWithNumbers()
            } else {
                return arrayOfRandomNumbers
            }
        }

        const createTheOptions = () => {
            var finalArray = []
            var randomNumberToFour = Math.floor(Math.random() * 4)
            var randomArrayWithUniqueNumbers = randomArrayWithNumbers()
            for (let i = 0; i < randomArrayWithUniqueNumbers.length; i++) {
                // add the options to array
                finalArray.push(
                    this.context.lang == 'greek' ?
                    this.props.words[randomArrayWithUniqueNumbers[i]].greek :
                    this.props.words[randomArrayWithUniqueNumbers[i]].english
                    )
            }
            finalArray.splice(randomNumberToFour, 0, rightAnswer)
            return finalArray
        }
            wordOptions = createTheOptions()
            return wordOptions

        } else {
                return wordOptions
        }
    }
    

    render() {
        if (this.props.words.length != 0) {
            this.randomArrayWithCorrectAnswer(this.state.generate)
        }

        return (
            <>
            <main className='multipleChoiceExercise' >
                <h1>
                    {this.props.words.length != 0 ? this.props.words[this.state.numberOfWord].german : 'loading...'}
                </h1>
                <div className='multipleChoices'>
                    <ul>
                        { wordOptions.length != 0 ? wordOptions.map( (each, index)=> {

                            return <li 
                            style={
                                this.styleCorrectOrFalse(
                                this.initStyling, 
                                index == this.state.selectedAnswer,
                                index == this.state.wrongAnswer
                                )
                            } onClick={ (e) => {

                                var userSelect = e.target.innerText
                                var rightWordToCheck = this.context.lang === 'greek' ?
                                this.props.words[this.state.numberOfWord].greek :
                                this.props.words[this.state.numberOfWord].english

                                if (this.state.userHasSelected == false) {
                                    if (userSelect === rightWordToCheck) {
                                        this.setState({
                                            generate: false,
                                            selectedAnswer: index,
                                            initStyling: false,
                                            userHasSelected: true,
                                            image: ''
                                        })
                                    } else {
                                        this.setState({
                                            generate: false,
                                            wrongAnswer: index,
                                            initStyling: false,
                                            userHasSelected: true,
                                            image: wrong
                                        })
                                    }
                                } else {
                                    return
                                }
                               

                            }}><span>{each}</span>
                            {this.state.selectedAnswer == index ? <Right /> : ''}
                            {this.state.wrongAnswer == index ? <Wrong /> : ''}
                            </li>
                        }) : ''}
                    </ul>
                </div>
                <div className='multipleButtons'>

                    <button style={this.state.wrongAnswer == null ?{opacity: 0.4} : {opacity: 1}} onClick={ () => {
                        if (this.state.wrongAnswer != null) {
                            this.setState({
                                generate: false,
                                selectedAnswer: null,
                                wrongAnswer: null,
                                initStyling: true,
                                userHasSelected: false
                                })
                        } else {
                            return
                        }                    
                    }
                    }>{this.context.lang == 'greek' ? 'δοκίμασε ξανά' : 'try again'}</button>


                    <button  onClick={ () => {
                        this.setState({
                            numberOfWord: this.state.numberOfWord + 1, 
                            generate: true,
                            selectedAnswer: null,
                            wrongAnswer: null,
                            initStyling: true,
                            userHasSelected: false
                        })
                            
                        if (this.state.numberOfWord > this.props.words.length - 2) {
                        this.setState({numberOfWord: 0})
                    }
                    }}>{this.context.lang == 'greek' ? 'επόμενη' : 'next'}</button>
                </div>
                <p>{this.state.numberOfWord + 1}/{this.props.words.length}</p>
            </main>
            </>
        )
    }
}


MultipleChoice.contextType = contextObject

export default withRouter(MultipleChoice)