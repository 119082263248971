export const termsOnGreek = `
Όροι και Προϋποθέσεις ("Όροι")

==============================

Τελευταία ενημέρωση: 15 Ιουλίου 2021 Διαβάστε τους παρόντες Όρους και Προϋποθέσεις ("Όροι", "Όροι και Προϋποθέσεις") προσεκτικά πριν χρησιμοποιήσετε τον ιστότοπο https://www.lexilogio.de (η "Υπηρεσία")("εμάς", "εμείς" ή "μας"). Η πρόσβαση και η χρήση της Υπηρεσίας εξαρτάται από την αποδοχή σας και συμμόρφωση με τους παρόντες Όρους. Αυτοί οι Όροι ισχύουν για όλους τους επισκέπτες, τους χρήστες και άλλους που έχουν πρόσβαση ή χρησιμοποιούν την Υπηρεσία. Με την πρόσβαση ή τη χρήση της Υπηρεσίας συμφωνείτε να δεσμεύεστε από τους παρόντες Όρους. Αν εσείς διαφωνείτε με οποιοδήποτε μέρος των όρων, τότε δεν μπορείτε να έχετε πρόσβαση στην Υπηρεσία. Οι Όροι και Προϋποθέσεις συμφωνιών για την lexilogio έχουν δημιουργηθεί με τη βοήθεια του (https://www.termsfeed.com/).



Σύνδεσμοι σε άλλους ιστότοπους

------------------------

Η Υπηρεσία μας περιέχει συνδέσμους προς ιστότοπους ή υπηρεσίες τρίτων που δεν ανήκουν ή ελέγχονται από την lexilogio. Η lexilogio δεν ελέγχει και δεν αναλαμβάνει καμία ευθύνη για το περιεχόμενο, τις πολιτικές απορρήτου ή τις πρακτικές οποιωνδήποτε ιστοσελίδων τρίτων ή άλλων Υπηρεσιών. Αναγνωρίζετε και συμφωνείτε ότι δεν πρέπει να θεωρείται η lexilogio υπεύθυνη, άμεσα ή έμμεσα, για τυχόν ζημιά ή ζημία που προκλήθηκε ή που υποτίθεται ότι προκαλείται από ή σε σχέση με τη χρήση ή την εξάρτηση από οποιοδήποτε τέτοιου είδους περιεχομένου, αγαθών ή υπηρεσιών διαθέσιμων σε ή μέσω τέτοιων δικτυακών τόπων ή Υπηρεσιών. 

Σας συνιστούμε να διαβάσετε τους όρους και τις πολιτικές απορρήτου των ιστοσελίδων ή των υπηρεσιών τρίτων που επισκέπτεστε.

Τερματισμός

Μπορούμε να τερματίσουμε ή να αναστείλουμε την πρόσβαση στην Υπηρεσία μας αμέσως, χωρίς προηγούμενη ειδοποίηση ή ευθύνη, για οποιονδήποτε λόγο, συμπεριλαμβανομένου χωρίς περιορισμό σε περίπτωση που ο χρήστης παραβιάσει τους Όρους. 
Όλες οι διατάξεις των Όρων, οι οποίες από τη φύση τους θα πρέπει να παραμείνουν μετά τον τερματισμό, θα παραμείνουν, και θα συμπεριλαμβάνουν, χωρίς περιορισμό,  ιδιοκτησία, προβλέψεις, απαλλαγές από την εγγύηση, αποζημίωση και περιορισμούς ευθύνης.

Ισχύουσα Νομοθεσία  

-------------

Αυτοί οι Όροι Χρήσης  διέπονται και  ερμηνεύονται σύμφωνα με τους νόμους της Βόρειας Ρηνανίας-Βεστφαλίας, της Γερμανίας, ανεξάρτητα από τις προβλέψεις συγκρουόμενων νομοθεσιών. 
Η αποτυχία μας να επιβάλουμε οποιοδήποτε δικαίωμα ή διάταξη αυτών των Όρων δεν θεωρείται παραίτηση από αυτά τα δικαιώματα. Εάν οποιαδήποτε διάταξη των παρόντων Όρων κριθεί άκυρη ή μη εκτελεστή από δικαστήριο, οι λοιπές διατάξεις αυτών των όρων θα παραμείνουν σε ισχύ. Αυτοί οι Όροι αποτελούν το σύνολο της συμφωνίας μεταξύ μας και αφορούν την Υπηρεσία μας, και δύνανται να αντικαταστήσουν οποιαδήποτε προηγούμενη
συμφωνία που ενδέχεται να έχουμε μεταξύ μας σχετικά με την Υπηρεσία.

Αλλαγές

-------

Διατηρούμε το δικαίωμα, κατά τη διακριτική μας ευχέρεια, να τροποποιήσουμε ή να αντικαταστήσουμε αυτούς τους Όρους οποιαδήποτε στιγμή. Εάν μια αναθεώρηση είναι σημαντική, θα προσπαθήσουμε να την παρέχουμε τουλάχιστον 30 ημέρες προτού τεθούν σε ισχύ οι νέοι όροι. Τι συνιστά ένα υλικό αλλαγή θα καθοριστεί κατά τη διακριτική μας ευχέρεια. Συνεχίζοντας να έχετε πρόσβαση ή να χρησιμοποιήσετε την Υπηρεσία μας μετά από αυτές τις αναθεωρήσεις, συμφωνείτε να δεσμεύεστε από τους αναθεωρημένους όρους. Αν δεν συμφωνείτε με τους νέους όρους, διακόψτε τη χρήση της Υπηρεσίας .

Επικοινωνήστε μαζί μας

----------

Αν έχετε οποιεσδήποτε απορίες σχετικά με αυτούς τους Όρους, επικοινωνήστε μαζί μας.

Οι πληροφορίες που περιέχονται στην ιστοσελίδα https://www.lexilogio.de (η "Υπηρεσία")
είναι για λόγους γενικής ενημέρωσης μόνο.

Το lexilogio δεν αναλαμβάνει καμία ευθύνη για σφάλματα ή παραλείψεις στο περιεχόμενο
στην υπηρεσία.

Σε καμία περίπτωση η lexilogio δεν ευθύνεται για οποιαδήποτε ειδική, άμεση, έμμεση, επακόλουθη ή συμπτωματική ζημιά, ή οποιαδήποτε ζημιά, ανεξάρτητα από το αν υπάρχει  σύμβαση, αμέλεια ή άλλη αδικοπραξία, που προκύπτει από ή σε σχέση με τη χρήση της Υπηρεσίας ή του περιεχομένου της Υπηρεσίας. Η lexilogio διατηρεί το δικαίωμα να κάνει προσθήκες, διαγραφές ή τροποποιήσεις στο περιεχόμενο της Υπηρεσίας ανά πάσα στιγμή χωρίς προηγούμενη ειδοποίηση.
Η lexilogio δεν εγγυάται ότι η Υπηρεσία είναι απαλλαγμένη από ιούς ή άλλα
επιβλαβή συστατικά.
`

export const termsOnEnglish = `
Terms and Conditions ("Terms")

============================

Last updated: 08 September 2019 Read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the website https://www.lexilogio.de (the "Service") ("us", "us "or" us "). Access to and use of the Service is subject to your acceptance and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not have access to the Service. The terms and conditions of agreements for lexilogio have been created with his help (https://www.termsfeed.com/).



Links to other sites

------------------------

Our Service contains links to third party websites or services not owned or controlled by lexilogio. lexilogio does not control or assume any responsibility for the content, privacy policies or practices of any third party websites or other Services. You acknowledge and agree that lexilogio shall not be liable, directly or indirectly, for any damage or injury caused or allegedly caused by or in connection with the use or dependence on any such content, goods or services available on or through such websites or Services.

We encourage you to read the terms and policies of the websites or third party services you visit.

Termination

We may terminate or suspend access to our Service immediately, without notice or liability, for any reason, including without limitation in the event that the user violates the Terms.
All provisions of the Terms, which by their nature should remain after termination, will remain, and will include, without limitation, ownership, provisions, warranty waivers, indemnities and limitations of liability.

Current legislation

-------------

These Terms of Use are governed by and construed in accordance with the laws of North Rhine-Westphalia, Germany, regardless of the provisions of the conflicting laws.
Our failure to enforce any right or provision of these Terms is not construed as a waiver of these rights. If any provision of these Terms is deemed invalid or unenforceable by a court, the other provisions of these Terms will remain in force. These Terms constitute the entire agreement between us and relate to our Service, and may supersede any prior
agreement we may have with each other regarding the Service.

Changes

-------

We reserve the right, in our sole discretion, to modify or replace these Terms at any time. If a review is important, we will try to provide it at least 30 days before the new terms take effect. What constitutes a material change will be determined at our discretion. By continuing to access or use our Service after these revisions, you agree to be bound by the revised terms. If you do not agree to the new terms, discontinue using the Service.

Contact us

----------

If you have any questions about these Terms, please contact us.

Information contained on the website https://www.lexilogio.de (the "Service")
is for general information purposes only.

lexilogio assumes no responsibility for errors or omissions in the content
in the service.

In no event is lexilogio liable for any specific, direct, indirect, consequential, or incidental damage, or any damage, whether contract, negligence, or other tort, resulting from or in connection with the use of the Service or the content of the Service. lexilogio reserves the right to make additions, deletions, or modifications to the content of the Service at any time without prior notice.
lexilogio does not guarantee that the Service is free of viruses or other
harmful ingredients.
`