export const categories = [
    {
        nameOnGreek: 'Καιρός',
        nameOnEnglish: 'Weather',
        parts: [ 
            {numberOfWords: 69, fileToFetch: 'weather part1.json'}
        ],
        nameOfImage: 'Καιρός.svg'
    },
    {
        nameOnGreek: 'Σπίτι',
        nameOnEnglish: 'Home',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'home part1.json'},
            {numberOfWords: 50, fileToFetch: 'home part2.json'},
            {numberOfWords: 50, fileToFetch: 'home part3.json'},
            {numberOfWords: 50, fileToFetch: 'home part4.json'},
            {numberOfWords: 27, fileToFetch: 'home part5.json'}
        ],
        nameOfImage: 'Σπίτι.svg'
    },
    {
        nameOnGreek: 'Οικογένεια',
        nameOnEnglish: 'Family',
        parts: [ 
            {numberOfWords: 28, fileToFetch: 'family part1.json'}
        ],
        nameOfImage: 'Οικογένεια.svg'
    },
    {
        nameOnGreek: 'Αριθμοί',
        nameOnEnglish: 'Numbers',
        parts: [
            {numberOfWords: 68, fileToFetch: 'numbers part1.json'},
            {numberOfWords: 30, fileToFetch: 'numbers part2.json'}

        ],
        nameOfImage: 'Αριθμοί.svg'
    },
    {
        nameOnGreek: 'Ρήματα',
        nameOnEnglish: 'Verbs',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'verbs part1.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part2.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part3.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part4.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part5.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part6.json'},
            {numberOfWords: 29, fileToFetch: 'verbs part7.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part8.json'},
            {numberOfWords: 50, fileToFetch: 'verbs part9.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Ζώα',
        nameOnEnglish: 'Animals',
        parts: [ 
            {numberOfWords: 57, fileToFetch: 'animals part1.json'}
        ],
        nameOfImage: 'Ζώα.svg'
    },
    {
        nameOnGreek: 'Ρούχα',
        nameOnEnglish: 'Clothes',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'clothes part1.json'},
            {numberOfWords: 23, fileToFetch: 'clothes part2.json'},
        ],
        nameOfImage: 'Ρούχα.svg'
    },
    {
        nameOnGreek: 'Φαγητό',
        nameOnEnglish: 'Food',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'food part1.json'},
            {numberOfWords: 50, fileToFetch: 'food part2.json'},
            {numberOfWords: 50, fileToFetch: 'food part3.json'},
            {numberOfWords: 50, fileToFetch: 'food part4.json'}
        ],
        nameOfImage: 'Φαγητό.svg'
    },
    {
        nameOnGreek: 'Λαχανικά',
        nameOnEnglish: 'Vegetables',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'vegetables.json'}
        ],
        nameOfImage: 'vegetables.svg'
    },
    {
        nameOnGreek: 'Φρούτα',
        nameOnEnglish: 'Fruits',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'fruits.json'}
        ],
        nameOfImage: 'fruits.svg'
    },
    {
        nameOnGreek: 'Χρώματα',   
        nameOnEnglish: 'Colors',
        parts: [ 
            {numberOfWords: 18, fileToFetch: 'colors part1.json'}
        ],
        nameOfImage: 'Χρώματα.svg'
    },
    {
        nameOnGreek: 'Μήνες',
        nameOnEnglish: 'Months',
        parts: [ 
            {numberOfWords: 23, fileToFetch: 'months part1.json'}
        ],
        nameOfImage: 'Μήνες.svg'
    },
    {
        nameOnGreek: 'Άθλημα',
        nameOnEnglish: 'Sport',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'sport part1.json'},
            {numberOfWords: 49, fileToFetch: 'sport part2.json'}
        ],
        nameOfImage: 'Σπορτ.svg'
    },
    {
        nameOnGreek: 'Σώμα',
        nameOnEnglish: 'Body',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'body part1.json'},
            {numberOfWords: 32, fileToFetch: 'body part2.json'}
        ],
        nameOfImage: 'Σώμα.svg'
    },
    {
        nameOnGreek: 'Αγάπη',
        nameOnEnglish: 'Love',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'love part1.json'},
            {numberOfWords: 50, fileToFetch: 'feelings part1.json'},
            {numberOfWords: 50, fileToFetch: 'feelings part2.json'}
        ],
        nameOfImage: 'Αγάπη.svg'
    },
    {
        nameOnGreek: 'Προσωπική Συλλογή',
        nameOnEnglish: 'My Collection',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'personal-collection part1.json'},
            {numberOfWords: 50, fileToFetch: 'personal-collection part2.json'},
            {numberOfWords: 50, fileToFetch: 'personal-collection part3.json'},
            {numberOfWords: 50, fileToFetch: 'personal-collection part4.json'},
            {numberOfWords: 50, fileToFetch: 'personal-collection part5.json'}
        ],
        nameOfImage: 'treasure.svg'
    },
    {
        nameOnGreek: 'Επίθετα',
        nameOnEnglish: 'Adectives',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'adj part1.json'},
            {numberOfWords: 50, fileToFetch: 'adj part2.json'},
            {numberOfWords: 50, fileToFetch: 'adj part3.json'},
            {numberOfWords: 50, fileToFetch: 'adj part4.json'},
            {numberOfWords: 50, fileToFetch: 'adj part5.json'},
            {numberOfWords: 50, fileToFetch: 'adj part6.json'},
            {numberOfWords: 50, fileToFetch: 'adj part7.json'},
            {numberOfWords: 50, fileToFetch: 'adj part8.json'},
            {numberOfWords: 50, fileToFetch: 'adj part9.json'},
            {numberOfWords: 50, fileToFetch: 'adj part10.json'},
            {numberOfWords: 8, fileToFetch: 'adj part11.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Επαγγέλματα',
        nameOnEnglish: 'Jobs',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'jobs part1.json'},
            {numberOfWords: 50, fileToFetch: 'jobs part2.json'}
        ],
        nameOfImage: 'jobs.svg'
    },
    {
        nameOnGreek: 'Χρονικά επιρρήματα',
        nameOnEnglish: 'Temporal adverbs',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'temporal adverbien part1.json'},
            {numberOfWords: 30, fileToFetch: 'temporal adverbien part2.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Τροπικά επιρρήματα',
        nameOnEnglish: 'Modal adverbs',
        parts: [ 
            {numberOfWords: 50, fileToFetch: 'modal adverbien part1.json'},
            {numberOfWords: 24, fileToFetch: 'modal adverbien part2.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Τοπικά επιρρήματα',
        nameOnEnglish: 'Local adverbs',
        parts: [ 
            {numberOfWords: 25, fileToFetch: 'lokal adverbien part1.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Αιτιολογικά επιρρήματα',
        nameOnEnglish: 'Casual adverbs',
        parts: [ 
            {numberOfWords: 28, fileToFetch: 'kausal adverbien part1.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    },
    {
        nameOnGreek: 'Σύνδεσμοι',
        nameOnEnglish: 'Conjunctions',
        parts: [ 
            {numberOfWords: 47, fileToFetch: 'conjunctions.json'}
        ],
        nameOfImage: 'Σελίδα.svg'
    }
]//array of objects


/*
var extract = []
//funny suff here still working on this
var parts = categories.map( (each) => {
        
    for (let i = 0; i < each.parts.length; i++) {
        console.log(each.parts);
        extract.push(each.parts[i].numberOfWords)
    }
})
function getArraySum(a){
    var total=0;
    for(var i in a) { 
        total += a[i];
    }
    return total;
}


var totalWords = getArraySum(extract); 
//console.log(parts)
//console.log(extract)

console.log(totalWords)


*/
/* shema

    {
        nameOnGreek: '',
        nameOnEnglish: '',
        parts: [ 
            {numberOfWords: , fileToFetch: ''}, 
            {numberOfWords: , fileToFetch: ''}
        ]
    }


*/