import React from 'react'
import contextObject from './context.js'

class Toast extends React.Component {
    render() { 

        return (
            <>  
            <div className="toastBox" style={{display: this.props.on === true ? 'flex' : 'none'}}>  
                <div>
                    <p>No Sound <span>&#128264;</span></p>    
                    <button onClick={
                        () => {
                            console.log(this.context)

                            this.context.issueFalse()
                        }
                    }>
                        &times;
                    </button>
                </div>
                <div>
                    <p>
                        {this.props.children}
                    </p>
                </div>
            </div>
            </>
        )
    }
}

Toast.contextType = contextObject
export default Toast