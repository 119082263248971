import React from 'react'
import DynamicContainer from './dynamicContainer.js'
import Categories from './categories.js'
import Parts from './parts.js'
import Words from './words.js'
import ExerciseTranslate from './exerciseTranslate.js'
import MultipleChoice from './exerciseMultipleChoice.js'
import { Route, withRouter, Link } from 'react-router-dom';  
import contextObject from '../context.js'
import Options from './exerciseOptions.js'
import { categories } from '../categoriesInformations.js'
import { level } from '../costumBack.js'
import FetchContainer from './fetchContainer.js'
import ToolBar from './toolBar.js'

class MainWords extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
           words: []
        }
    }
    

    render() {
       
      
        return (
            <main>
                <Route path="/app/categories" exact='true'>
                    <ToolBar />
                    <Categories toTop={this.props.toTop}/> 
                </Route>
                <Route path="/app/categories/:categoryName" exact='true' >
                    <ToolBar />
                    <DynamicContainer>   
                        <Parts /> 
                    </DynamicContainer>
                </Route>
                <Route path="/app/categories/:categoryName/:whichPart" >
                    <FetchContainer/>
                </Route>
            </main>
        )
    }
}

MainWords.contextType = contextObject
export default withRouter(MainWords)