import React from 'react'
import image from '../images/mainImage.svg'
import vector1 from '../images/vector 1.svg'
import vector2 from '../images/vector 2.svg'
import contextObject from '../context.js'



import { Link } from 'react-router-dom';

class LandingPage extends React.Component {

    render() {
        
        return (
            <div className='landingPage'>
                <nav className='landingNav'>

                </nav>
                {
                    this.context.lang === 'greek' ?
                
                <main className='landingMain'>
                        <div>
                            <h1>
                                Μάθε τις πιο βασικές λέξεις στα Γερμανικά.
                            </h1>
                            <div className='stats'>
                                <span>2300+ λέξεις</span>
                                <span>22 κατηγορίες</span>
                            </div>

                                    <button className='landingButton' onClick={this.props.toTop}>
                                        <Link to='/app/categories'>ΞΕΚΙΝΑ</Link>  
                                    </button>
                            
                        </div>
                        <div>
                            <img src={image}/>
                        </div>
                </main>
:
                <main className='landingMain'>
                        <div>
                            <h1>
                                Learn the basic german words
                            </h1>
                            <div className='stats'>
                                <span>2300+ words</span>
                                <span>22 categories</span>
                                <span></span>
                            </div>

                                    <button className='landingButton' onClick={this.props.toTop}>
                                        <Link to='/app/categories'>START</Link>  
                                    </button>
                            
                        </div>
                        <div>
                            <img src={image}/>
                        </div>
                </main>
                }
                <img src={vector1}/>
                <img src={vector2}/>
                
            </div>
        )
    }
}

LandingPage.contextType = contextObject

export default LandingPage