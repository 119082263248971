import React from 'react'
import contextObject from '../context.js'
import { termsOnGreek, termsOnEnglish } from '../terms.js'
import { textGreek, textEnglish } from './text.jsx'

class Info extends React.Component {

    constructor() {
        super()

        this.state = {
            whatToDisplay: 'infoContent'
        }
    }


    render () {

        return (
            <main>
                <div className='textBox'>
                    <nav>
                        <ul>
                            <li
                            style={this.state.whatToDisplay === 'infoContent' ? {fontWeight: 'bold', color: 'black'} : {fontWeight: '400'}}
                            onClick={ () => { this.setState( {whatToDisplay: 'infoContent'}) }  }
                            >
                                {this.context.lang == 'greek' ? 'γενικές πληροφορίες' : 'about'}
                            </li>

                            <li 
                            style={this.state.whatToDisplay === 'contactContent' ? {fontWeight: 'bold', color: 'black'} : {fontWeight: '400'}}
                            onClick={ () => { this.setState( {whatToDisplay: 'contactContent'}) }  }
                            >
                                {this.context.lang == 'greek' ? 'επικοινωνία' : 'contact'}
                            </li>

                            <li 
                            style={this.state.whatToDisplay === 'termsOfUse' ? {fontWeight: 'bold', color: 'black'} : {fontWeight: '400'}}
                            onClick={ () => { this.setState( {whatToDisplay: 'termsOfUse'}) }  }
                            >
                                {this.context.lang == 'greek' ? 'όροι χρήσης' : 'terms of use'}
                            </li>              
                        </ul>
                    </nav>
                    <div className='infoContent' 

                    style={this.state.whatToDisplay == 'infoContent' ? {display: 'flex'} : {display: 'none'}
                    
                    }>
                            {this.context.lang == 'greek' ? textGreek() : textEnglish}      
                    </div>

                    <div className='contactContent'

                    style={this.state.whatToDisplay == 'contactContent' ? {display: 'flex'} : {display: 'none'}
                    
                    }>
                        <h2 style={{marginTop: '80px', fontSize: '18px'}}>email: mentesidisio@gmail.com</h2>
                    </div>

                    <div className='termsOfUse'

                    style={this.state.whatToDisplay == 'termsOfUse' ? {display: 'flex'} : {display: 'none'}
     
                    }>
                        <p>{this.context.lang == 'greek' ? termsOnGreek : termsOnEnglish}</p>
                    </div>
                </div>
            </main>

        )
    }
}

Info.contextType = contextObject

export default Info

