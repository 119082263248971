function cleanWord(word) {
    var cleanWord = '';
    var firstFour = word.slice(0, 4)

    if (firstFour == 'der ' || firstFour == 'die ' || firstFour == 'das ' || firstFour == 'wie ') {
        cleanWord = word.slice(4, word.length)

        return cleanWord
    } else {
        return word
    }
}

export default cleanWord