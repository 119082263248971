import React from 'react'
import ReactDom from 'react-dom'
import App from './layout/theApp.js'
import LandingPage from './landingPage/theLandingPage.js'
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import contextObject from './context.js'
import NotFound from './layout/notFound.js'
import Toast from './toast.jsx'

//css
import './theApp.css'
import './landingPage.css'
import './responsive.css'

class Worter extends React.Component {

    constructor(props) {
        super(props)

        this.toTop = this.toTop.bind(this)

        this.state = {
            lang: 'greek',
            speakerIssue: false,
            issueTrue: () => {
                this.setState({speakerIssue: true})
            },
            issueFalse: () => {
                this.setState({speakerIssue: false})
            },
        }
        
    }

    
    toTop() {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }


    render() { 

        return (
            <>  
            <contextObject.Provider value={this.state}>
                <BrowserRouter>
                    <Switch> 
                        <Route path="/" exact>
                            <LandingPage toTop={this.toTop} />
                        </Route>
                        <Route path="/app" >
                            <App toTop={this.toTop} />
                        </Route>
                        <Route path="*">
                                <NotFound />
                        </Route>
                    </Switch>
                </BrowserRouter>
                <Toast on={this.state.speakerIssue}>
                    H λειτουργία αυτή δεν υποστηρίζεται από το συγκεκριμένο πρόγραμμα περιήγησης (browser).
                    <hr />
                    επιλέξτε σύγχρονα προγράμματα περιήγησης όπως: Chrome, Firefox, Opera
                </Toast>
            </contextObject.Provider>
            
            </>
        )
    }
}


ReactDom.render(<Worter />, document.querySelector('#app'))