
export function langName(state) {
    var greek = 'nameOnGreek'
    var english = 'nameOnEnglish'

    if (state == 'greek') {
        return greek
    } else {
        return english
    }
}
