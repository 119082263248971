
function level(pathName, categoryName, whichPart) {
    
    var level1 = `/app/categories`
    var level2 = `/app/categories/${categoryName}`
    var level3 = `/app/categories/${categoryName}/${whichPart}`
    var level4 = `/app/categories/${categoryName}/${whichPart}/exercises`
    var level5first = `/app/categories/${categoryName}/${whichPart}/exercises/first`
    var level5second = `/app/categories/${categoryName}/${whichPart}/exercises/second`
    var level5third = `/app/categories/${categoryName}/${whichPart}/exercises/third`

    if (pathName == level5first || pathName == level5second || pathName == level5third) {
        return level4
    }
    if (pathName == level4) {
        return level3
    }
    if (pathName == level3) {
        return level2
    }
    if (pathName == level2) {
        return level1
    }
    if (pathName == level1) {
        return 
    }

}

export { level }