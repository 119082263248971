import React from 'react'
import logo from '../images/logo.svg'
import { Link } from 'react-router-dom';

class NavBar extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            open: 'flex'
        }
    }

    handleClick() {
        this.props.burgerClicked()
        this.setState({open: this.state.open == 'flex' ? 'none' : 'flex'})
    }
    render() {
        return (
            <nav className='layoutNav'>
                <main>
                    <div className='logo'>
                        <Link to='/' exact='true'>
                            λexilogio.de
                        </Link>
                    </div>

                    <div className='burger' onClick={this.handleClick.bind(this)}>


                        <div className='burgerOpen' style={{display: this.state.open}}>
                            <p></p>
                            <p><div></div></p>
                            <p></p>
                        </div>
                        <div className='burgerClose' style={{display: this.state.open == 'none' ? 'flex' : 'none'}}>
                            <p><div></div></p>
                            <p></p>
                        </div>
                    </div>
                    
                </main>
            </nav>
        )
    }      
} 

    
export default NavBar
