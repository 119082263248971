export function textGreek() {
    return (
        <>
            <h2>ΣΥΝΤΟΜΗ ΠΕΡΙΓΡΑΦΗ</h2>
            <hr />
            <p>Το lexilogio.de είναι μια ιστοεφαρμογή που περιλαμβάνει τις πιο βασικές λέξεις της γερμανικής γλώσσας οι οποίες θα σου φανούν χρήσιμες εάν ξεκινάς να μαθαίνεις γερμανικά, όπως επίσης θα σου φανούν χρήσιμες στην καθημερινότητά σου εάν έχεις μετακομίσει στη Γερμανία για δουλειά ή για σπουδές.</p>
            <ol>
                <li>Έχει δημιουργηθεί με σκοπό να βοηθήσει τους Έλληνες στη Γερμανία.</li>
                <li>Είναι στην αρχική της μορφή, θα υπάρξουν στο μέλλον αλλαγές και διορθώσεις στις λέξεις.</li>
            </ol>
            <p>
            Αν βρείτε λάθη ή έχετε να προτείνετε κάποια βελτίωση επικοινωνήστε μαζί μου:
            <u><strong> mentesidisio@gmail.com</strong></u>
            </p>
        </>
    ) 
}

export const textEnglish = `
Lexilogio.de is a simple web application which contains the basic words of german language. It will help you if you are a student or you moved to Germany for work.
`








