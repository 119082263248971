import React from 'react'
import Words from './words.js'
import ExerciseTranslate from './exerciseTranslate.js'
import MultipleChoice from './exerciseMultipleChoice.js'
import { Route, withRouter, Switch } from 'react-router-dom';  
import Options from './exerciseOptions.js'
import { categories } from '../categoriesInformations.js'
import ToolBar from './toolBar.js'
import Spinner from './components/spinnerBasic.js';
import NotFound from '../layout/notFound.js'
import  { langName } from './nameOfCategory.js'
import contextObject from '../context.js'


class FetchContainer extends React.Component {

        constructor() {
            super()
            this.state = {
                theWords: []
            }
        }

        componentDidMount () {
            //var connected = window.navigator.onLine
            //console.log(connected);
            var url = window.location.origin;
            var numberOfCategory = categories.findIndex((each) => {
                
            if (each[langName(this.context.lang)] === this.props.match.params.categoryName) {
                return each
            }
            })
            var numberOfPart = parseInt(this.props.match.params.whichPart)

            fetch(`${url}/word lists/${categories[numberOfCategory].parts[numberOfPart].fileToFetch}`,{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
               }
               )
                .then( (res) => {    
                return res.json()
                })
                .then( (data) => {
                    this.setState({theWords: data})
                })
                .catch(error => {
                //console.error('Error:', error);
                });
        }

        render () {
            var spin = ''
            if (this.state.theWords.length === 0) {
                spin = true
            } else {
                spin = false
            }
            return (
                <>
                <Switch>
                <Route path="/app/categories/:categoryName/:whichPart" exact >
                    <ToolBar exerciseButton={true} />
                    <Spinner active={spin}/>
                    <Words words={this.state.theWords} />
                </Route>
                <Route path="/app/categories/:categoryName/:whichPart/exercises" exact >
                    <ToolBar exerciseButton={false} />
                    <Options />
                </Route>
                <Route path="/app/categories/:categoryName/:whichPart/exercises/third" exact >
                    <ToolBar exerciseButton={false} />
                    <Spinner active={spin}/>
                    <ExerciseTranslate words={this.state.theWords}/>
                </Route>
                <Route path="/app/categories/:categoryName/:whichPart/exercises/second" exact >
                    <ToolBar exerciseButton={false} />
                    <Spinner active={spin}/>
                    <MultipleChoice words={this.state.theWords}/>
                </Route>
                <Route>
                    <NotFound />
                </Route>
                </Switch>
                </>
            )
        }
}
FetchContainer.contextType = contextObject

export default withRouter(FetchContainer)