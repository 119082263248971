import React from 'react'
import four from '../images/four.svg'
import multiple from '../images/multiple2.svg'
import wordtranslation from '../images/wordtranslation.svg'
import { Link } from 'react-router-dom';
import contextObject from '../context.js'
import { categories } from '../categoriesInformations.js'
import { withRouter } from "react-router";
import  { langName } from './nameOfCategory.js'

class Options extends React.Component {
    componentDidMount () {
     
    }

    render () {
        var numberOfCategory = categories.findIndex((each) => {
            if (each[langName(this.context.lang)] == this.props.match.params.categoryName) {
                return each
            }
            })
            var numberOfPart = parseInt(this.props.match.params.whichPart)

        
   
        return (
            <div className='chooseExercise'>
                <div className='choices'>
                {/* 
                    <div>
                        <img src={four}/>

                        {this.context.theWords.length != 0 ?                       
                        <Link to={`/app/categories/${categories[this.context.indexOfCategory].nameOnGreek}/${this.context.whichPart}/exercises/first`}>
                            <button>start</button>
                        </Link>
                        :''}

                    </div>
                */}
                    <div>
                        <img src={multiple}/>

                        <Link to={`/app/categories/${categories[numberOfCategory][langName(this.context.lang)]}/${numberOfPart}/exercises/second`}>
                            <button>start</button>
                        </Link>
                        

                    </div>
                    
                    <div>
                        <img src={wordtranslation}/>
                        
                        <Link to={`/app/categories/${categories[numberOfCategory][langName(this.context.lang)]}/${numberOfPart}/exercises/third`}>
                            <button>start</button>
                        </Link>

                    </div>
                </div>
            </div>
            )
        }
}
Options.contextType = contextObject
export default withRouter(Options)