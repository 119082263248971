import React from 'react'

class PagButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            key: this.props.number
        }
    }

    sendTheNumber () {
        this.props.set(this.props.number)
    }
    
    render() {
    var cssActive = {
        backgroundColor: '#11B7C1',
        borderRadius: '50px',
        width: '30px',
        height: '30px',
        padding: '2px 10px',
        cursor: 'pointer',
        color: 'white',
        margin: '0 3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
    var cssInactive = {
        backgroundColor: 'transparent',
        borderRadius: '50px',
        width: '30px',
        height: '30px',
        padding: '2px 10px',
        cursor: 'pointer',
        color: 'black',
        margin: '0 3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    } 
    
    return <div 
    style={this.props.activeThis == false ? cssInactive : cssActive} 
    onClick={this.sendTheNumber.bind(this)}> 
    {this.props.number} 
    </div>
    }
}

export default PagButtons