import React from 'react'
import contextObject from '../context.js'
import { categories } from '../categoriesInformations.js'
import { withRouter } from "react-router";

class ExerciseTranslate extends React.Component {
        constructor(props) {
            super(props)
            this.myRef = React.createRef()

            this.state = { 
                numberOfTheWord: 0,
                howManyWords: '',
                userInput: '',
                rightAnswer: 'default',
                germanToTranslated: true,
                caretPosition: '',
                theInput: this.myRef
            }
            this.inputStyling = this.inputStyling.bind(this)
            this.message = this.message.bind(this)
            this.checkWord = this.checkWord.bind(this)
            this.useKeys = this.useKeys.bind(this)
            this.nextWord = this.nextWord.bind(this)
            this.changeArrowDirection = this.changeArrowDirection.bind(this)
            

        }

        autoFocus() {
            setTimeout( () => {
                this.state.theInput.current.focus()
            },1); 
        }

        nextWord () {
            /*
            setTimeout( () => {
                this.state.theInput.focus()
                this.state.theInput.setSelectionRange(this.state.caretPosition, this.state.caretPosition)

            },1); 
            */
            var toCompare = (this.props.words.length - 2)
            this.setState({
                userInput: '',
                rightAnswer: 'default'
            })

             if (this.state.numberOfTheWord > toCompare) {
                this.setState({numberOfTheWord: 0})
             } else {
                 this.setState({numberOfTheWord: this.state.numberOfTheWord + 1}) 
             }
        }
        checkWord () {
            var languageToLearn = this.context.lang === 'greek' ? 'greek' : 'english'


            if (this.state.germanToTranslated == true) {
                if (this.state.userInput.trim() == this.props.words[this.state.numberOfTheWord][languageToLearn]) {
                    this.setState({rightAnswer: 'rightAnswer'})
                 } else if (this.state.userInput.trim() != this.props.words[this.state.numberOfTheWord][languageToLearn]) {  
                    this.setState({rightAnswer: 'falseAnswer'})
                 }
            } else if (this.state.germanToTranslated == false) {
                if (this.state.userInput.trim() == this.props.words[this.state.numberOfTheWord].german) {
                    this.setState({rightAnswer: 'rightAnswer'})
                 } else if (this.state.userInput.trim() != this.props.words[this.state.numberOfTheWord].german) {
                    this.setState({rightAnswer: 'falseAnswer'})  
                 }
            }
             

        }
        getUserInput (event) {
            var userInput = event.target.value
            var position = event.target.selectionStart

            this.setState({userInput: userInput, caretPosition: position})

            if (userInput == "" ) {
                this.setState({rightAnswer: 'default'})
            }
        }
        caretPosition (event) {
            var position = event.target.selectionStart

            this.setState({caretPosition: position})
        }
        inputStyling () {
            var cssForInput;

            if (this.state.rightAnswer == 'default') {
                cssForInput = {
                    borderColor: 'rgb(72, 85, 87)',
                    borderStyle: 'solid',
                    outline: 'none',
                    borderWidth: '2px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none'
                }
                return cssForInput

            } else if (this.state.rightAnswer == 'rightAnswer') {
                cssForInput = {
                    borderColor: 'rgba(12, 255, 8, 1)',
                    backgroundColor: 'rgba(12, 255, 8, 0.08)',
                    borderStyle: 'solid',
                    outline: 'none',
                    borderWidth: '2px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none'
                }
                return cssForInput

            } else if (this.state.rightAnswer == 'falseAnswer') {
                cssForInput = {
                    borderColor: 'rgba(243, 0, 0, 1)',
                    backgroundColor: 'rgba(243, 0, 0, 0.08)',
                    borderStyle: 'solid',
                    outline: 'none',
                    borderWidth: '2px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none'
                }
                return cssForInput
            }
        }
        message () {
            if (this.state.rightAnswer == 'default') {
                return <span></span>
            } 
            else if (this.state.rightAnswer == 'rightAnswer') {
            
                return <span>{this.context.lang === 'greek' ? 'σωστό' : 'correct'}</span>

            } else if (this.state.rightAnswer == 'falseAnswer') {
                if (this.state.germanToTranslated == true) {
                    return (
                        <>
                        <span>{this.context.lang === 'greek' ? 'σωστή απάντηση: ' : 'right answer: '}</span> 
                        <span>{this.context.lang === 'greek' ? 
                        this.props.words[this.state.numberOfTheWord].greek :
                        this.props.words[this.state.numberOfTheWord].english
                        }</span>
                        </>
                    )
                } else if (this.state.germanToTranslated == false) {
                    return (
                        <>
                        <span>σωστή απάντηση: </span> 
                        <span>{this.props.words[this.state.numberOfTheWord].german}</span>
                        </>
                    )
                }
                
            }
        }
        useKeys (event) {
            var position;
            //console.log(event.keyCode);
            if (event.keyCode == 13) {
                this.checkWord()
            }
            if (event.shiftKey == true && event.keyCode == 13) {
                
                this.nextWord()
            } 
            if (event.keyCode == 39) {
                position = event.target.selectionStart;
                this.setState({caretPosition: position})
            } 
            if (event.keyCode == 37) {
                position = event.target.selectionStart;
                this.setState({caretPosition: position})
            } 
        }
        changeLanguage () {
            this.setState({rightAnswer: 'default'})

            this.state.germanToTranslated == true ? this.setState({germanToTranslated: false}) : this.setState({germanToTranslated: true})
        }
        changeArrowDirection () {
            var css;
            
            this.state.germanToTranslated == true ? 
            css = {transform: 'rotate(180deg)', transition: '0.5s', display: 'flex',justifyContent: 'center', alignItems: 'center'} :
             css = {transform: 'rotate(0deg)', transition: '0.5s', display: 'flex',justifyContent: 'center', alignItems: 'center'}

            return css
        }
        
        createNewString (e) {
            var letter = e.target.innerText
            var userInput = this.state.userInput
            var theInput = this.state.theInput
            var caretPosition = this.state.caretPosition
            var stringToCaretStart = userInput.substring(0, caretPosition)
            var stringFromCaretEnd = userInput.substring(caretPosition, userInput.length)
            var newString = `${stringToCaretStart}${letter}${stringFromCaretEnd}`

            this.setState({userInput: newString, caretPosition: this.state.caretPosition + 1})


            //to stay the input on focus and the caret at the position which user wanted

            if (this.state.theInput != '') {
                 
                setTimeout( () => {
                    this.state.theInput.current.focus()
                    this.state.theInput.current.setSelectionRange(this.state.caretPosition, this.state.caretPosition)
    
                },1); 
            }
        }

        
    render() {

 
        var umlaut = ['ä', 'ö', 'ü', 'ß']
        this.autoFocus()
        var languageToLearn = this.context.lang === 'greek' ? 'greek' : 'english'

        return (
            <>
            <main className='exerciseTranslate'>
                <div className='containerOne'>
                    <div className='boxToToggle'>
                        <div onClick={this.changeLanguage.bind(this)} style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}> 
                            <span>german </span>

                            <div style={this.changeArrowDirection()}>
                                <svg width="18" height="10" viewBox="0 0 38 30" fill="black" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3384 1.46614L0.849816 14.2502C0.398645 14.6483 0.398645 15.3517 0.849816 15.7498L15.3384 28.5339C15.9841 29.1036 17 28.6451 17 27.784V2.21597C17 1.35486 15.9841 0.896403 15.3384 1.46614Z" />
                                <path d="M36 9H18C17.4477 9 17 9.44772 17 10V20C17 20.5523 17.4477 21 18 21H36C36.5523 21 37 20.5523 37 20V10C37 9.44772 36.5523 9 36 9Z" />
                                </svg> 
                            </div>
                            
                            <span>{this.context.lang == 'greek' ? ' greek' : ' english'}</span>
                        </div>
                    </div>
                    <div className='boxForWord'>
                        {
                        this.state.germanToTranslated == true 

                        ?

                        <h1>{this.props.words.length == 0 ? <h5>loading...</h5> : this.props.words[this.state.numberOfTheWord].german }</h1>                          

                        :

                        <h1>{this.props.words.length == 0 ? <h5>loading...</h5> : this.props.words[this.state.numberOfTheWord][languageToLearn] }</h1>}

                        
                    </div>
                    <div className='boxForSpecialKeys'>
                        <div>
                            {
                                umlaut.map((each) => {
                                    return <span onClick={this.createNewString.bind(this)}>{each}</span>
                                })
                            } 
                        </div>
                    </div>
                    <div className='boxForInput'>
                        <input
                        value={this.state.userInput} 
                        onChange={this.getUserInput.bind(this)} 
                        onKeyUp={this.useKeys} 
                        onClick={this.caretPosition.bind(this)}
                        style={this.inputStyling()}
                        type='text'
                        autoFocus
                        ref={this.myRef}
                        />
                    </div>
                    <div className='boxForMessage'>
                        {this.message()}
                    </div>
                    <div className='boxForButtons'>
                        <button onClick={this.checkWord} >Check</button>
                        <button onClick={this.nextWord}>{this.context.lang == 'greek' ? 'Επόμενη' : 'Next'}</button>    
                    </div>
                    <div className='boxToCount'>
                        <span>{(this.state.numberOfTheWord + 1)}</span><span>/</span><span>{this.props.words.length}</span>    
                    </div>
                </div>
                
                
                
                
                            
                            
       
                
                
            </main>
   
            </>
        )
    }
}
ExerciseTranslate.contextType = contextObject
export default withRouter(ExerciseTranslate)
