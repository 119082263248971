import React from 'react'
import { categories }from '../categoriesInformations.js'
import { Link, withRouter } from 'react-router-dom';
import contextObject from '../context.js'
import DynamicContainer from './dynamicContainer.js'
import PagButtons from './components/pagButtons.js'

class Categories extends React.Component {

    constructor () {
        super()

        this.state = {
            pagginationStarts: 0,
            whichButton: 1
        }
    }
    getClickedButton(key) {
        this.setState({
            whichButton: key,
            pagginationStarts: key - 1
          })
  
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
      }//my way


    render () {
        
        var displayArray = []

        for (let i = 0; i < categories.length; i += 16) {
            var nu = categories.slice(i, i + 16)
            displayArray.push(nu)
        }
        //sliced array of categories end

        var pagElements = [] 

        for (var i = 0; i < displayArray.length; i++) { 
        
            pagElements.push(
                <PagButtons 
                number={i+1} 
                //onclick get the state of each button
                set={this.getClickedButton.bind(this)}
                activeThis={this.state.whichButton == i + 1 ? true : false}
                />
                )
        }

        return (
            <>
            <DynamicContainer>
                {

                     displayArray[this.state.pagginationStarts].map((each, index) => {
                        var img = require(`../images/${each.nameOfImage}`).default

                        return (
                            <Link to={`/app/categories/${this.context.lang === 'greek' ? each.nameOnGreek : each.nameOnEnglish}`}>
                                <div className='box' onClick={           
                                    this.props.toTop
                                }>
                                    <img src={img} />
                                    <h1>{this.context.lang === 'greek' ? each.nameOnGreek : each.nameOnEnglish}</h1>
                                </div>
                            </Link>   
                        )
                     })//map

                }
                </DynamicContainer>
                <div className='paginationContainer'>
                {pagElements}
                </div>
            </>
        )
    }
}

Categories.contextType = contextObject
export default withRouter(Categories)

