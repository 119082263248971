import React from 'react'
import { Link } from 'react-router-dom';
import { categories }from '../categoriesInformations.js'
import contextObject from '../context.js'

import { withRouter } from 'react-router-dom';  

     
class Parts extends React.Component {
    constructor (props) {
        super(props)

        this.findIndexOfArray = this.findIndexOfArray.bind(this)

    }
    
    findIndexOfArray() {
        var num = ''
            categories.map((each, index) => {
                
                var categoryName = this.context.lang === 'greek' ? each.nameOnGreek : each.nameOnEnglish

                if (categoryName === this.props.match.params.categoryName) { 
                    num = index
                    return
                }

            })

        return num
    }



    render () {

        var indexOfArray = this.findIndexOfArray()

        return (            


                categories[indexOfArray].parts.map((each, index) => {   
                    return (  
                        
                        <>
                            <Link to={

                                `/app/categories/${this.props.match.params.categoryName}/${index}`
                                 
                                }>
                                <div className='boxParts'>
                                    <h1>{this.context.lang === 'greek' ? 'μέρος' : 'part'} {index+1}</h1>
                                    <h2>{each.numberOfWords} {this.context.lang === 'greek' ? 'λέξεις' : 'words'}</h2>
                                </div>
                            </Link>
                        </>       
                    )
                })
            
        )
    }
}

Parts.contextType = contextObject

export default withRouter(Parts)