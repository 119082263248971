import React from 'react'
import { Link } from 'react-router-dom';  


class NotFound extends React.Component {

    render () {
        return (
            <>
            <h1>Not Found</h1>
            <button style={{
                width: '210px',
                height: '80px',
                color: 'white',
                backgroundColor: '#11A2C1',
                marginTop: '20px',
                border: 'none',
                borderRadius: '12px',
                cursor: 'pointer',
                fontSize: '17px',
                fontWeight: 'bold'
            }}>
                <Link to='/' style={{color: 'white', height: '80px'}}>   
                     restart the application
                </Link>   
                </button>
            </>
        )
        
    }
}

export default NotFound