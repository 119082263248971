import React from 'react'


class CategorieIcon extends React.Component {

    render() {
        return (
            <div onClick={()=>{this.props.clicked(true, false)}}>

            <svg width="38" height="38" viewBox="0 0 196 196" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.00001 48L3 8C3 5.23858 5.23858 3 8 3H48C50.7614 3 53 5.23858 53 8V48C53 50.7614 50.7614 53 48 53H8.00001C5.23858 53 3.00001 50.7614 3.00001 48Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M73 48L73 8C73 5.23858 75.2386 3 78 3H118C120.761 3 123 5.23858 123 8V48C123 50.7614 120.761 53 118 53H78C75.2386 53 73 50.7614 73 48Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M143 48L143 8C143 5.23858 145.239 3 148 3H188C190.761 3 193 5.23858 193 8V48C193 50.7614 190.761 53 188 53H148C145.239 53 143 50.7614 143 48Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M143 118L143 78C143 75.2386 145.239 73 148 73H188C190.761 73 193 75.2386 193 78V118C193 120.761 190.761 123 188 123H148C145.239 123 143 120.761 143 118Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M73 118L73 78C73 75.2386 75.2386 73 78 73H118C120.761 73 123 75.2386 123 78V118C123 120.761 120.761 123 118 123H78C75.2386 123 73 120.761 73 118Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M3.00001 118L3 78C3 75.2386 5.23858 73 8 73H48C50.7614 73 53 75.2386 53 78V118C53 120.761 50.7614 123 48 123H8.00001C5.23858 123 3.00001 120.761 3.00001 118Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M3.00001 188L3 148C3 145.239 5.23858 143 8 143H48C50.7614 143 53 145.239 53 148V188C53 190.761 50.7614 193 48 193H8.00001C5.23858 193 3.00001 190.761 3.00001 188Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M73 188L73 148C73 145.239 75.2386 143 78 143H118C120.761 143 123 145.239 123 148V188C123 190.761 120.761 193 118 193H78C75.2386 193 73 190.761 73 188Z" stroke={this.props.color} stroke-width="5"/>
            <path d="M143 188L143 148C143 145.239 145.239 143 148 143H188C190.761 143 193 145.239 193 148V188C193 190.761 190.761 193 188 193H148C145.239 193 143 190.761 143 188Z" stroke={this.props.color} stroke-width="5"/>
            </svg>



            <p style={{color: this.props.color}}>{this.props.lang === 'greek' ? 'Κατηγορίες' : 'Categories'}</p>
            </div>
        )

    }
}

export default CategorieIcon